<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                            <v-text-field
                                v-model="item.label"
                                :label="trans('fields.common.label')"
                                :error-messages="errors.label"
                                @input="clearError('label')"
                            ></v-text-field>
                            <v-select
                                v-if="options"
                                :items="options.promoCodeTypes"
                                :label="trans('fields.promoCode.type')"
                                v-model="item.type"
                                :error-messages="errors.type"
                                @input="clearError('type')"
                            ></v-select>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="itemId" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "PromoCodeForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
        },
        data(){
            return {
                item: {
                    id: null,
                    label: '',
                    type: null,
                },
                options: {},
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                this.loadOptions();
                if (this.itemId){
                    axios.get(this.route('admin.promoCodes.show', this.itemId)).then( response => {
                        this.item = response.data;
                    }).catch( err => {
                        console.log(err);
                    })
                }
            },
            loadOptions(){
                axios.post(this.route('admin.options'), {
                    promoCodeTypes: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.promoCodes.store'), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.promoCodes.update', this.itemId), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.itemId
                    ? this.trans('pages.promoCode.newDialogTitle')
                    : this.trans('pages.promoCode.editDialogTitle');
            },
        }
    }
</script>
